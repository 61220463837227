import React from "react";
import config from "../config";
import { useState, useCallback, useEffect } from "react";
import { Container, Box, Typography } from "@mui/material";
import { CardMedia } from "@mui/material";
import Grid from "@mui/material/Grid";
//import Ranking from "./Ranking";
import BasicTable from "./BasicTable";
import Menubar from "./Menubar";
import {Link} from 'react-router-dom';

const RankPage = () => {
  const [one, setone] = useState({});

  const fetchone = useCallback(async () => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/top/`, {
        method: "GET",
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Fetched data:", data); // デバッグ用のログ
        setone(data);
      } else {
        console.error("Failed to fetch items:", response.status);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  useEffect(() => {
    fetchone();
  }, [fetchone]);

  return (
    <div>
      <Container maxWidth="sm" sx={{border:"1px solid black"}}>
        <Menubar/>
        <Box sx={{ bgcolor: "#cfe8fc",border:"1px solid black"}}>
          <Typography variant="h5" >
            レジェンズ最強キャラランキング
          </Typography>
        </Box>
        <Box sx={{ bgcolor: "#cfe8fc" ,border:"1px solid black"}}>
          <Typography variant="h5" >
            1位 <br/>{one.name}<br/>レアリティ {one.labels?.rarity_label || "N/A"} <br/>
            属性 {one.labels?.color_label || "N/A"}
          </Typography>
        </Box>
        <Grid
          container
          alignItems="center" // 縦方向の中央揃え
          justifyContent="center" // 横方向の中央揃え
          sx={{ border:"1px solid black",padding:"10px"}} // 高さを設定して中央揃えを適用
        >
          <Grid item>
          <Link to={`/one/${one.id}`}  >
            <CardMedia
              component="img"
              image={one.image}
              alt="Paella dish"
              sx={{
                 // 画像の最大幅を300pxに制限
                maxHeight: "150px",
                maxWidth: "80%", // 画像の幅をコンテナに合わせる
                width: "150px",
                height: "auto", // 高さを自動調整
                border:"1px solid black"
              }}
            />
            </Link>
          </Grid>
        </Grid>
        <Box sx={{ bgcolor: "#cfe8fc",border:"1px solid black"}}>
          <Typography variant="h5" >
            評価<br/>{one.detail}
          </Typography>
        </Box>
        <Box sx={{ bgcolor: "#cfe8fc"}}>
          <BasicTable/>
            
          
        </Box>
      </Container>
    </div>
  );
};

export default RankPage;
