import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import config from '../config';
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/material";

const One = () => {
  const [one, setone] = useState([]);
  const { id } = useParams();
  const navigate =useNavigate();

  const fetchone = useCallback(async () => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/one/${id}/`, {
        method: 'GET',
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Fetched data:", data); // デバッグ用のログ
        setone(data);
      } else {
        console.error('Failed to fetch items:', response.status);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }, [id]);

  const deleteone = useCallback(async () => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/delete/${id}/`, {
        method: 'DELETE',
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Fetched data:", data); // デバッグ用のログ
        setone(data);
      } else {
        console.error('Failed to fetch items:', response.status);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }, [id]);

  const handleClick = () => {
    navigate(`/edit/${id}`);
};

  useEffect(() => {
    fetchone();
  }, [fetchone]);

  return (
    <div>
      {one ? (
        <Container maxWidth="sm" sx={{ border: "1px solid black" }}>
          <button onClick={deleteone}>delete</button>
          <button onClick={handleClick}>edit</button>
          <p>ID: {one.id}</p>
          <p>Created At: {one.created_at}</p>
          <p>Color: {one.color}</p>
          <p>Rarity: {one.rarity}</p>
          <p>name: {one.name}</p>
          <p>detail: {one.detail}</p>
          <img src={one.image} alt="item" />
          </Container>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default One;