import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import config from "../config";
import { useState } from "react";
import { useEffect } from "react";
import { CardMedia } from "@mui/material";
//import axios from "axios";
//import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
//import SendIcon from '@mui/icons-material/Send';
//import CheckIcon from '@mui/icons-material/Check';
import Sender from "./Sender";

export default function BasicTable() {
  const [charactors, setcharactors] = useState([]);
  const navigate =useNavigate();

  useEffect(() => {
    fetchranking();
  }, []);

  const fetchranking = async () => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/charactor/`, {
        method: "GET",
      });

      if (response.ok) {
        const data = await response.json();
        setcharactors(data);
      } else {
        console.error("Failed to fetch items:", response.status);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  

  const handleClick = (item) => {
    navigate(`/one/${item.id}`);
};

  return (
    <TableContainer component={Paper}>
      <Table  aria-label="simple table">
        <TableHead>
          <TableRow sx={{ border: '1px solid black' }}>
            <TableCell sx={{ border: '1px solid black' }}>charactor</TableCell>
            <TableCell sx={{ border: '1px solid black' }}align="right">color</TableCell>
            <TableCell sx={{ border: '1px solid black' }}>detail</TableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
          {charactors.map((item) => (
            <TableRow
              key={item.id}
              sx={{ border: '1px solid black' }}
            >
              <TableCell sx={{ border: '1px solid black' ,width:"15%"}}component="th" scope="row">
              <CardMedia
                  component="img"
                  image={item.image}
                  alt="green iguana"
                  sx={{
                    //width: "15%", // 画像の幅を固定
                    //height: "auto", // 高さを自動調整
                    //border:"1px solid black",
                    //padding: "6px",
                  }}
                  onClick={() => handleClick(item)}
                />
                {item.name}
              </TableCell>
              <TableCell sx={{ width:'10%',border: '1px solid black' }}align="right">{item.labels.color_label}</TableCell>
              <TableCell sx={{ border: '1px solid black' }}>
              {item.detail}
              </TableCell>
              <TableCell sx={{ width:'15%',border: '1px solid black' }}>
              <Sender item={item.id}/>
              </TableCell>
              
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
