import React from "react";
import axios from "axios";
import config from "../config";
import { Button } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useState } from "react";
import CheckIcon from "@mui/icons-material/Check";

const Sender = ({ item }) => {
  const [icon, setIcon] = useState(<SendIcon />);

  // ボタンが押されたときにアイコンを変更する関数
  const handleClick = () => {
    setIcon(<CheckIcon />); // アイコンを SendIcon から CheckIcon に変更
    make(); // make 関数を呼び出す
  };

  const make = async () => {
    try {
      const response = await axios.get(`${config.apiBaseUrl}/vote/${item}/`, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("Response status code:", response.status);
      console.log("Response data:", response.data);
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  return (
    <Button variant="contained" endIcon={icon} onClick={() => handleClick()}>
      投票
    </Button>
  );
};

export default Sender;
