import './App.css';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
//import CommentPost from './components/CommentPost';
import Makecharactor from './components/Makecharactor.jsx';

//import ActionAreaCard from './components/ActionAreaCard';
import One from './components/One';
import Edit from './components/Edit';
import RankPage from './components/RankPage';
//import BasicTable from './components/BasicTable';
import Menubar from './components/Menubar';

function App() {
  return (
    <Router>
      
      <Routes>
        
        <Route path="make" element={<Makecharactor/>} />
        
        <Route path="" element={<RankPage/>}/>
        <Route path="try" element={<Menubar/>}/>
        <Route path="one/:id" element={<One/>}/>
        <Route path="edit/:id" element={<Edit/>}/>
      </Routes>
    </Router>
  );
}

export default App;