import React, { useState } from "react";
import axios from "axios";
import config from "../config"; // APIベースURLを設定するファイルをインポート
import { Container } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import CheckIcon from "@mui/icons-material/Check";
import { Button } from "@mui/material";

const Makecharactor = () => {
  const [color, setColor] = useState("");
  const [rarity, setRarity] = useState("");
  const [image, setImage] = useState(null);
  const [name, setName] = useState("");
  const [detail, setDetail] = useState("");
  const [icon, setIcon] = useState(<SendIcon />);

  const make = async () => {
    const formData = new FormData();
    formData.append("color", color);
    formData.append("rarity", rarity);
    formData.append("image", image);
    formData.append("name", name);
    formData.append("detail", detail); //なくても送れる

    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/charactor/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Response status code:", response.status);
      console.log("Response data:", response.data);
    } catch (error) {
      console.error("Error posting data:", error);
    }
    setIcon(<CheckIcon />);
  };

  return (
    <Container maxWidth="sm" sx={{ border: "1px solid black" }}>
      <select
        value={color}
        onChange={(e) => setColor(e.target.value)}
        style={{
          padding: "10px",
          marginBottom: "10px",
          width: "100%",
          borderRadius: "8px",
          border: "1px solid #ccc",
        }}
      >
        <option value="">属性</option>
        <option value="1">Red</option>
        <option value="2">Blue</option>
        <option value="3">Green</option>
        <option value="4">Yellow</option>
        <option value="5">Purple</option>
      </select>
      <select
        value={rarity}
        onChange={(e) => setRarity(e.target.value)}
        style={{
          padding: "10px",
          marginBottom: "10px",
          width: "100%",
          borderRadius: "8px",
          border: "1px solid #ccc",
        }}
      >
        <option value="">レアリティ</option>
        <option value="2">limited</option>
        <option value="1">ultra</option>
        <option value="3">sparking</option>
      </select>
      <input
        type="file"
        placeholder="image"
        onChange={(e) => setImage(e.target.files[0])}
        style={{
          padding: "10px",
          marginBottom: "10px",
          width: "100%",
          borderRadius: "8px",
          border: "1px solid #ccc",
        }}
      />
      <input
        type="text"
        placeholder="name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        style={{
          padding: "10px",
          marginBottom: "10px",
          width: "100%",
          borderRadius: "8px",
          border: "1px solid #ccc",
        }}
      />
      <input
        type="text"
        placeholder="detail"
        value={detail}
        onChange={(e) => setDetail(e.target.value)}
        style={{
          padding: "10px",
          marginBottom: "10px",
          width: "100%",
          borderRadius: "8px",
          border: "1px solid #ccc",
        }}
      />
      <Button variant="contained" endIcon={icon} onClick={make}>
      投稿
    </Button>
    </Container>
  );
};

export default Makecharactor;
