import React from "react";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

const Menubar = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 0 }}>
      <Box sx={{ display: "flex", padding: 0 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "33.33%",
            height: 30,
            bgcolor: "lightblue",
            border: "1px solid black",
          }}
        >
          <Link href="/make" underline="hover" color="inherit">
            <Typography gutterBottom>投稿する</Typography>
          </Link>
        </Box>
        <Box
          sx={{
            width: "33.33%",
            height: 30,
            bgcolor: "lightgreen",
            border: "1px solid black",
          }}
        />
        <Box
          sx={{
            width: "33.33%",
            height: 30,
            bgcolor: "lightcoral",
            border: "1px solid black",
          }}
        />
      </Box>
      <Box sx={{ display: "flex", padding: 0 }}>
        <Box
          sx={{
            width: "33.33%",
            height: 30,
            bgcolor: "lightblue",
            border: "1px solid black",
          }}
        />
        <Box
          sx={{
            width: "33.33%",
            height: 30,
            bgcolor: "lightgreen",
            border: "1px solid black",
          }}
        />
        <Box
          sx={{
            width: "33.33%",
            height: 30,
            bgcolor: "lightcoral",
            border: "1px solid black",
          }}
        />
      </Box>
    </Box>
  );
};

export default Menubar;
