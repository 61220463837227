import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import config from '../config';
import axios from 'axios';
import { Container } from "@mui/material";

const Edit = () => {
  const [one, setone] = useState([]);
  const { id } = useParams();

  const fetchone = useCallback(async () => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/one/${id}/`, {
        method: 'GET',
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Fetched data:", data); // デバッグ用のログ
        setone(data);
      } else {
        console.error('Failed to fetch items:', response.status);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }, [id]);


  

  const [color, setColor] = useState('');
  const [rarity, setRarity] = useState('');
  const [image, setImage] = useState(null);
  const [name, setName] = useState('');
  const [detail, setDetail] = useState('');

  const make = async () => {
    const formData = new FormData();
    if (color) formData.append('color', color);
    if (rarity) formData.append('rarity', rarity);
    if (image) formData.append('image', image);
    if (name) formData.append('name', name);
    if (detail) formData.append('detail', detail);//なければ送れない,一つでも空だとbad

    try {
      const response = await axios.patch(`${config.apiBaseUrl}/edit/${id}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });
      console.log("Response status code:", response.status);
      console.log("Response data:", response.data);
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  useEffect(() => {
    fetchone();
  }, [fetchone]);

  useEffect(() => {
    if (one) {
      setColor(one.color || '');
      setRarity(one.rarity || '');
      setImage(one.image || null);
      setName(one.name || '');
      setDetail(one.detail || '');
    }
  }, [one]);

  return (
    <Container maxWidth="sm" sx={{ border: "1px solid black" }}>
      {one ? (
        <div>
          <p>ID: {one.id}</p>
          <p>Created At: {one.created_at}</p>
          <p>Color: {one.color}</p>
          <p>Rarity: {one.rarity}</p>
          <p>name: {one.name}</p>
          <p>detail: {one.detail}</p>
          <img src={one.image} alt="item" />
        </div>
      ) : (
        <p>Loading...</p>
      )}
      <input
        type="number"
        placeholder="color"
        value={color}
        onChange={(e) => setColor(e.target.value)}
        style={{
          padding: '10px',
          marginBottom: '10px',
          width: '100%',
          borderRadius: '8px',
          border: '1px solid #ccc'
        }}
      />
      <input
        type="number"
        placeholder="rarity"
        value={rarity}
        onChange={(e) => setRarity(e.target.value)}
        style={{
          padding: '10px',
          marginBottom: '10px',
          width: '100%',
          borderRadius: '8px',
          border: '1px solid #ccc'
        }}
      />
      <input
        type="file"
        placeholder="image"
        onChange={(e) => setImage(e.target.files[0])}
        style={{
          padding: '10px',
          marginBottom: '10px',
          width: '100%',
          borderRadius: '8px',
          border: '1px solid #ccc'
        }}
      />
      <input
        type="text"
        placeholder="name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        style={{
          padding: '10px',
          marginBottom: '10px',
          width: '100%',
          borderRadius: '8px',
          border: '1px solid #ccc'
        }}
      />
      <input
        type="text"
        placeholder="detail"
        value={detail}
        onChange={(e) => setDetail(e.target.value)}
        style={{
          padding: '10px',
          marginBottom: '10px',
          width: '100%',
          borderRadius: '8px',
          border: '1px solid #ccc'
        }}
      />
      <button
        onClick={make}
        style={{
          padding: '10px',
          width: '100%',
          backgroundColor: 'blue',
          color: 'white',
          borderRadius: '8px',
          border: 'none',
          cursor: 'pointer'
        }}
      >
        Post
      </button>
      </Container>
  );
};

export default Edit;